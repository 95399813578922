<template>
    <section class="exchanges" id="exchanges">
        <svg-icon name="borderTopDark"/>
        <v-container>
            <v-layout justify-center>
                <div class="flex xs12 sm12 md12 lg12 xl9">
                    <div class="exchanges__in inner">
                        <div class="exchanges__content">
                            <div class="title-main title-main--white">
                                {{ $t('exchangesTitle') }}
                            </div>
                            <ul class="list">
                                <li class="list__item" v-for="(item, idx) in list" :key="idx">
                                    <div class="list__bullet">
                                        <svg-icon name="arrow"></svg-icon>
                                    </div>
                                    <h3 class="list__title" v-html="$t(item.title)"></h3>
                                </li>
                            </ul>
                            <div class="exchanges__action">
                                <router-link tag="button" class="btn-main" :to="{name: getRouteForCurrentLanguage('development')}">
                                    <span class="button-link-text" v-on:click="trackEvent('Search')">{{$t('btnMore')}}</span>
                                </router-link>
                            </div>
                        </div>
                        <!--<div class="exchanges__pic">-->
                            <!--<img :src="require(`@/assets/img/bg/exchanges.png`)" alt="">-->
                        <!--</div>-->
                    </div>

                </div>
            </v-layout>
        </v-container>
        <svg class="exchanges__svg" preserveAspectRatio="none" width="100%" height="202" viewBox="0 0 1462 202" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.848633 196.311V201.721H1461.92V12.5423C1461.92 12.5423 1118.86 -44.1196 723.543 92.9409C328.224 230.002 0.848633 196.311 0.848633 196.311Z" fill="#F4F7FF"/>
        </svg>
    </section>
</template>

<script>
    import { getRouteForCurrentLanguage, trackEvent } from '@/helpers/locale-helper'
    export default {
        data() {
            return {
                list: [
                    {
                        'title': 'exchangesItem-1'
                    },
                    {
                        'title': 'exchangesItem-2'
                    },
                    {
                        'title': 'exchangesItem-3'
                    },
                    {
                        'title': 'exchangesItem-4'
                    },
                    {
                        'title': 'exchangesItem-5'
                    },
                    {
                        'title': 'exchangesItem-6'
                    },
                    {
                        'title': 'exchangesItem-7'
                    },
                    {
                        'title': 'exchangesItem-8'
                    },
                ]
            }
        },
        methods: {
          getRouteForCurrentLanguage,
          trackEvent
        }
    }
</script>
<style lang="sass">
@import './exchanges.sass'
</style>