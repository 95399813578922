<template>
    <section class="social">
        <div class="social__top">
            <svg-icon name="socialTopBg"/>
        </div>
        <div class="social__wrapper">


            <v-container>
                <v-layout justify-center>
                    <div class="flex xs12 sm12 md12 lg12 xl9">
                        <div class="social__in inner">
                            <!--<ul class="social__list">-->
                                <!--<li class="social__item" v-for="(item,idx) in list" :key="idx" :class="item">-->
                                    <!--<img :src="require(`@/assets/img/social/${item}.svg`)" alt="">-->
                                <!--</li>-->
                            <!--</ul>-->
                            <!--<div class="social__pic">-->
                                <!--<img :src="require(`@/assets/img/social/socials.webp`)" alt="">-->
                            <!--</div>-->

                            <div class="social__content">
                                <div class="social__title title-main title-main--white">
                                    {{ $t('socialTitle') }}
                                </div>
                                <ul class="list">
                                    <li class="list__item" v-for="(item, idx) in list" :key="idx">
                                        <div class="list__bullet">
                                            <svg-icon name="arrow"></svg-icon>
                                        </div>
                                        <h3 class="list__title" v-html="item"></h3>
                                    </li>
                                </ul>
                                <div class="social__action">
                                    <buttonCallPopup classList="btn-main" type="btnMore"></buttonCallPopup>
                                </div>
                            </div>
                        </div>

                    </div>
                </v-layout>
            </v-container>


        </div>
        <div class="social__bottom">
            <svg-icon name="socialBottomBg"/>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                list: ['Facebook', 'Twitter', 'Medium', 'Instagram', 'YouTube', 'LinkedIN', 'Telegram group']
            }
        }
    }
</script>
<style lang="sass">
    @import './social.sass'
</style>