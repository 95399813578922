<template>
    <section class="development" id="development">
        <div class="development__top">
            <svg-icon name="bottom"/>
        </div>
        <div class="development__wrapper">
            <v-container>
                <v-layout justify-center>
                    <div class="flex xs12 sm12 md12 lg12 xl9">
                        <div class="development__in">
                            <div class="title-main text-center">
                                {{ $t('developmentTitle') }}
                            </div>
                            <div class="development__content">
                                <div class="development__bg">
                                    <img :src="require(`@/assets/img/development/bg.svg`)" alt="">
                                </div>
                                <div class="development__list">
                                    <div class="development__line development__line--1"></div>
                                    <div class="development__line development__line--2"></div>
                                    <div class="development__line development__line--3"></div>
                                    <div class="development__item" v-for="(item, idx) in list" :key="idx" :class="item.icon">
                                        <div class="development__pic">
                                            <img :src="require(`@/assets/img/development/${item.icon}.svg`)" loading="lazy" alt="">
                                        </div>
                                        <h3 class="development__title" v-html="$t(item.title)"></h3>
                                    </div>
                                </div>



                                <!--<ul class="list">-->
                                <!--<li class="list__item" v-for="(item, idx) in list" :key="idx">-->
                                <!--<div class="list__bullet">-->
                                <!--<svg-icon name="arrow"></svg-icon>-->
                                <!--</div>-->
                                <!--<h3 class="list__title" v-html="item.title"></h3>-->
                                <!--</li>-->
                                <!--</ul>-->

                            </div>

                        </div>

                    </div>
                </v-layout>
            </v-container>
            <div class="order">
                <v-container>
                    <v-layout justify-center>
                        <div class="flex xs12 sm12 md12 lg12 xl9">
                            <div class="order__in">
                                <div class="order__content">
                                    <div class="order__title title-main">
                                        {{ $t('orderTitle') }}
                                    </div>
                                    <div class="order__subtitle title-secondary">
                                        {{ $t('orderSubtitle') }}
                                    </div>
                                    <div class="development__action">
                                        <buttonCallPopup classList="btn-main" type="btnMore"></buttonCallPopup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-layout>
                </v-container>
            </div>
        </div>



    </section>
</template>

<script>
    export default {
        data() {
            return {
                list: [
                    {
                        'title': 'developmentItem-1',
                        'icon': 'mobile'
                    },
                    {
                        'title': 'developmentItem-2',
                        'icon': 'crm'

                    },
                    {
                        'title': 'developmentItem-3',
                        'icon': 'web'

                    },
                    {
                        'title': 'developmentItem-4',
                        'icon': 'land'

                    },
                    {
                        'title': 'developmentItem-5',
                        'icon': 'online'

                    },
                    {
                        'title': 'developmentItem-6',
                        'icon': 'saas'

                    },
                ]
            }
        }
    }
</script>
<style lang="sass">
    @import './development.sass'
</style>