<template>
    <section class="blockchain" id="blockchain">
        <v-container>
            <v-layout justify-center>
                <div class="flex xs12 sm12 md12 lg12 xl9">
                    <h2 class="title-main text-center">
                        {{ $t('blockchainTitle') }}
                    </h2>
                    <ul class="blockchain__list">
                        <li class="blockchain__item" v-for="(item, idx) in list" :key="idx">
                            <div class="blockchain__pic">
                                <svg-icon :name="item.icon"/>
                            </div>
                            <h3 class="blockchain__title title-secondary" v-html="$t(item.title)"></h3>
                        </li>
                    </ul>
                    <div class="blockchain__action text-center">
                        <router-link tag="button" class="btn-main" :to="{name: getRouteForCurrentLanguage('blockchain')}" >
                            <span class="button-link-text" v-on:click="trackEvent('Search')">{{$t('btnMore')}}</span>
                        </router-link>
                    </div>
                </div>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
  import { getRouteForCurrentLanguage, trackEvent } from '@/helpers/locale-helper'
  export default {
        data() {
            return {
                list: [
                    {
                        'title': 'blockchainItem-1',
                        'icon': 'blockchain-1'
                    },
                    {
                        'title': 'blockchainItem-2',
                        'icon': 'blockchain-2'
                    },
                    {
                        'title': 'blockchainItem-3',
                        'icon': 'blockchain-3'
                    },
                    {
                        'title': 'blockchainItem-4',
                        'icon': 'blockchain-4'
                    },
                    {
                        'title': 'blockchainItem-5',
                        'icon': 'blockchain-5'
                    },
                    {
                        'title': 'blockchainItem-6',
                        'icon': 'blockchain-6'
                    },
                ]
            }
        },
        methods: {
          getRouteForCurrentLanguage,
          trackEvent
        },
    }
</script>
<style lang="sass">
@import './blockchain.sass'

.button-link-text
    text-transform: uppercase 
    color: #fff
    font-size: 14px
    letter-spacing: -0.119772px
    white-space: pre
    line-height: 14px
    font-family: 'Roboto', sans-serif
    font-weight: bold
</style>