<template>
    <section class="contracts" id="contracts">

        <svg-icon name="top"/>

        <v-container>
            <v-layout justify-center>
                <div class="flex xs12 sm12 md12 lg12 xl9">
                    <h2 class="title-main text-center">
                        {{ $t('contractsTitle') }}
                    </h2>
                    <div class="contracts__in inner">
                        <ul class="contracts__list">
                            <li class="contracts__item" v-for="(item, idx) in list" :key="idx">
                                <div class="contracts__pic">
                                    <img :src="require(`@/assets/img/contracts/${item.icon}.svg`)" loading="lazy" alt="">
                                </div>
                                <h3 class="contracts__title title-secondary" v-html="$t(item.title)"></h3>
                            </li>
                        </ul>
                        <div class="contracts__action text-center">
                            <router-link tag="button" class="btn-main" :to="{name: getRouteForCurrentLanguage('contracts')}" >
                                <span class="button-link-text" v-on:click="trackEvent('Search')">{{$t('btnMore')}}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </v-layout>
        </v-container>


    </section>
</template>

<script>
    import { getRouteForCurrentLanguage, trackEvent } from '@/helpers/locale-helper'
    export default {
        data() {
            return {
                list: [
                    {
                        'title': 'contractsItem-1',
                        'icon': 'contracts-1'
                    },
                    {
                        'title': 'contractsItem-2',
                        'icon': 'contracts-2'
                    },
                    {
                        'title': 'contractsItem-3',
                        'icon': 'contracts-3'
                    },
                    {
                        'title': 'contractsItem-4',
                        'icon': 'contracts-4'
                    },
                ]
            }
        },
        methods: {
          getRouteForCurrentLanguage,
          trackEvent
        },
    }
</script>
<style lang="sass">
    @import './contracts.sass'
</style>