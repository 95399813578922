<template>

    <section class="advantages" id="advantages">
        <svg-icon name="borderTop"/>
        <v-container>
            <v-layout justify-center>
                <div class="flex xs12 sm12 md12 lg12 xl9">
                    <h2 class="title-main text-center">
                        {{ $t('advantagesTitle') }}
                    </h2>
                    <div class="advantages__in inner">
                        <ul class="advantages__list">
                            <li class="advantages__item" v-for="(item, idx) in list" :key="idx">
                                <div class="advantages__pic">
                                    <img :src="require(`@/assets/img/advantages/${item.icon}.svg`)" loading="lazy" alt="">
                                </div>
                                <h3 class="advantages__title title-secondary" v-html="$t(item.title)"></h3>
                            </li>
                        </ul>
                    </div>
                </div>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                list: [
                    {
                        'title': 'advantagesItem-1',
                        'icon': 'advantages-1'
                    },
                    {
                        'title': 'advantagesItem-2',
                        'icon': 'advantages-2'
                    },
                    {
                        'title': 'advantagesItem-3',
                        'icon': 'advantages-3'
                    },
                    {
                        'title': 'advantagesItem-4',
                        'icon': 'advantages-4'
                    },
                    {
                        'title': 'advantagesItem-5',
                        'icon': 'advantages-5'
                    },
                    {
                        'title': 'advantagesItem-6',
                        'icon': 'advantages-6'
                    },
                ]
            }
        }
    }
</script>
<style lang="sass">
    @import './advantages.sass'
</style>