<template>
  <div class="out">
    <intro-section></intro-section>

    <blockchain-section></blockchain-section>

    <advantages-section></advantages-section>

    <exchanges-section></exchanges-section>

    <trade-section></trade-section>

    <contracts-section></contracts-section>

    <development-section></development-section>

    <social-section></social-section>

    <technology-section></technology-section>

    <section-footer paragraph="sectionfooter.paragraph-default"></section-footer>

    <!--<v-container>-->
      <!--<v-layout justify-center>-->
        <!--<v-flex xs12 sm12 md12 lg12 xl9>-->
          <!--<v-img-->
                  <!--:src="require('../assets/logo.svg')"-->
                  <!--class="my-3"-->
                  <!--contain-->
                  <!--height="200"-->
          <!--/>-->
          <!--<v-row class="text-center">-->
            <!--<v-col cols="6">-->
              <!--<button class="btn btn-main">-->
                <!--НУЖЕН ПРОСЧЁТ ПРОЕКТА?-->
              <!--</button>-->


            <!--</v-col>-->
            <!--<v-col cols="6">-->
              <!--<button class="btn btn-secondary">-->
                <!--Связаться-->
              <!--</button>-->
            <!--</v-col>-->

          <!--</v-row>-->
        <!--</v-flex>-->
      <!--</v-layout>-->

    <!--</v-container>-->

  </div>

</template>

<script>
  import intro from './intro/intro.vue'
  import blockchain from './blockchain/blockchain.vue'
  import advantages from './advantages/advantages.vue'
  import exchanges from './exchanges/exchanges.vue'
  import trade from './trade/trade.vue'
  import contracts from './contracts/contracts.vue'
  import development from './development/development.vue'
  import social from './social/social.vue'
  import technology from './technology/technology.vue'
  import sectionFooter from '@/pages/asections/sectionFooter.vue'
  export default {
    name: '',
    components: {
        'intro-section': intro,
        'blockchain-section': blockchain,
        'advantages-section': advantages,
        'exchanges-section': exchanges,
        'trade-section': trade,
        'contracts-section': contracts,
        'development-section': development,
        'social-section': social,
        'technology-section': technology,
        'section-footer': sectionFooter
    },
    data: () => ({

    }),
  }
</script>
