<template>
    <section class="trade" id="trade">

        <v-container>
            <v-layout justify-center>
                <div class="flex xs12 sm12 md12 lg12 xl9">
                    <div class="trade__in inner">
                        <!--<div class="trade__bg">-->
                            <!--<img :src="require(`@/assets/img/bg/trade.png`)" alt="">-->
                        <!--</div>-->
                        <div class="trade__content">
                            <div class="title-main">
                                {{ $t('tradeTitle') }}
                            </div>
                            <ul class="trade__list">
                                <li class="trade__item" v-for="(item, idx) in list" :key="idx">
                                    <div class="trade__pic">
                                        <svg-icon :name="item.icon"/>
                                    </div>
                                    <h3 class="trade__title title-secondary" v-html="$t(item.title)"></h3>
                                    <div class="trade__sublist" v-if="item.sub">
                                        <div class="trade__sub" v-for="(sub, key) in item.sub" :key="key">
                                            <div class="trade__bullet">
                                                <svg-icon name="arrow-blue"/>
                                            </div>
                                            <h4>{{$t(sub)}}</h4>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="trade__action">
                                <router-link tag="button" class="btn-main" :to="{name: getRouteForCurrentLanguage('launch')}" >
                                    <span class="button-link-text" v-on:click="trackEvent('Search')">{{$t('btnMore')}}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                </div>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
    import { getRouteForCurrentLanguage, trackEvent } from '@/helpers/locale-helper'
    export default {
        data() {
            return {
                list: [
                    {
                        'title': 'tradeItem-1',
                        'icon': 'trade-1',
                        sub: ['tradeList-1','tradeList-2','tradeList-3']
                    },
                    {
                        'title': 'tradeItem-2',
                        'icon': 'trade-2',
                    },
                    {
                        'title': 'tradeItem-3',
                        'icon': 'trade-3',
                    },
                ]
            }
        },
        methods: {
          getRouteForCurrentLanguage,
          trackEvent
        },
    }
</script>
<style lang="sass">
@import './trade.sass'

.button-link-text
    text-transform: uppercase 
    color: #fff
    font-size: 14px
    letter-spacing: -0.119772px
    white-space: pre
    line-height: 14px
    font-family: 'Roboto', sans-serif
    font-weight: bold
</style>